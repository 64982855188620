<template>
  <section class="success-wrapper">
    <div class="icon" />
  </section>
</template>

<script>
import {
  appgoBack
} from '@/utils'
export default {
  data () {
    return {
    }
  },
  created () {
    setTimeout(() => {
      appgoBack({})
    }, 3000)
  }
}
</script>

<style lang="less" scoped>

</style>
